import { useMemo, useState } from 'react';
import './App.css';


import { SessionModel, SnackbarModel } from './models';
import { ctxSession, ctxSnackbar } from './store';

import Auth from './viewmodels/Auth';
import Site from './viewmodels/Site';


function App() {

    // let main = <div>No Website</div>;

    const [session, setSession] = useState<SessionModel | null>({
        token: localStorage.getItem('token')
    });

    const main = useMemo(() => {
        if (session && session.token) {
            return <Site />;
        } else {
            // return <Site />
            return <Auth />;
        }
    }, [session]);

    const [snack, setSnack] = useState<SnackbarModel[] | null>([]);




    return (
        <ctxSession.Provider value={{ data: session, set: setSession }} >
            <ctxSnackbar.Provider value={{ data: snack, set: setSnack }}>
                {main}
            </ctxSnackbar.Provider>
        </ctxSession.Provider>
    );
}

export default App;
